import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class AlphabetFilterController extends Controller {

    static targets = ['form', 'search']

    searchResults(e) {
        const sellers = this.formTarget.dataset.sellers.split(',')
        const input = e.target.value
        const data = sellers.filter(item => item.toLowerCase().includes(input.toLowerCase()));

        const resultsContainerId = this.formTarget.dataset.id
        const resultsContainer = document.getElementById(resultsContainerId)

        // hide old results
        resultsContainer.childNodes.forEach(node => {
            const isLink = node.tagName === 'A'

            if (node.classList?.contains('no-results') && data.length) {
                node.remove()
            }


            if (isLink) {
                const vendorName = node.id?.split('option-')[1]
                if (data.includes(vendorName)) {
                    node.classList.remove('hidden')
                    node.classList.add('input-group')
                } else {
                    node.classList.remove('input-group')
                    node.classList.add('hidden')
                }

            }
        })

        if (!data.length && !input.length) {
            resultsContainer.childNodes.forEach(node => {
                const isLink = node.tagName === 'A'
                if (isLink && node.classList.contains('original')) {
                    node.classList.toggle('input-group')
                    node.classList.toggle('hidden')
                }
            })
        }

        if (!data.length && input.length) {
            const currentElem = document.getElementById('no-results')

            if (currentElem) {
                const textElement = document.getElementById('no-result-query')
                textElement.textContent = ` “${input}”`

            } else {
                const noResults = document.createElement('div');
                noResults.setAttribute('id', 'no-results')
                const buttonElem = document.createElement('a');
                var text = document.createTextNode('No Results for ');
                var queryText = document.createTextNode(` “${input}”`);
                var buttonText = document.createTextNode('× Clear Search');
                var queryElem = document.createElement('span');
                queryElem.setAttribute('id', 'no-result-query')

                buttonElem.setAttribute("class", "btn-link-primary text-xs block mt-2 w-full");
                buttonElem.onclick = () => {
                    this.searchTarget.value = ''
                    this.searchResults()
                }
                noResults.setAttribute("class", "no-results text-sm text-center");
                queryElem.setAttribute("class", "text-secondary pl-1");
                buttonElem.appendChild(buttonText);
                noResults.appendChild(text);
                queryElem.appendChild(queryText);
                noResults.appendChild(queryElem);
                noResults.appendChild(buttonElem);
                resultsContainer.appendChild(noResults)
            }

        }
    }

    scrollToLetter(e) {
        const letter = e.target.textContent.trim().toLowerCase()
        const sellers = this.formTarget.dataset.sellers.split(',')
        const firstSeller = sellers.find(s => s.toLowerCase().startsWith(letter))
        const resultsContainerId = this.formTarget.dataset.id

        const container = document.getElementById(resultsContainerId)
        const element = document.getElementById(`option-${firstSeller}`)

        if (container && element) {
            const elementRect = element.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();

            const relativeTop = elementRect.top - containerRect.top;

            container.scrollTo({
                top: relativeTop,
                behavior: 'smooth'
            });
        }
    }
}
