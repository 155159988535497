import { Controller } from "@hotwired/stimulus"

export default class SearchDropdownController extends Controller {

    static targets = ['dropdown']

    initialize() {
      this.dropdownTarget.addEventListener('change', (e) => {
          window.document.location.href = e.target.value
      })
    }

}
